<template>
    <v-container fluid>
        <v-row row wrap>
            <template v-if="loading">
                <v-col cols="12" class="text-center" mt-4>
                <v-progress-circular
                    :width="3"
                    color="blue"
                    indeterminate
                ></v-progress-circular>
                </v-col>
            </template>
            <template v-else>
                <v-col cols="9" :md="2" mt-0 ml-2>
                    <v-select :items="statusOptions" v-model="status"
                    outlined
                    label="Order Status"
                    ></v-select>
                </v-col>
                <v-col xs2 ml-1 mt-1 align="center">
                    <div class="text-center">        
                        <v-btn icon large color="primary" @click="refresh" :loading="loading" :disabled="loading">
                            <v-icon>refresh</v-icon>
                        </v-btn>
                    </div>
                </v-col>
                <v-col cols="12" :md="3" mt-0 ml-2 mr-2>
                    <v-select :items="products" v-model="selectedProduct"
                    outlined
                    label="Product"
                    ></v-select>
                </v-col>
                <v-col cols="12" :md="5" mt-0 ml-2 mr-2>
                    <v-text-field
                        v-model="search"
                        append-icon="search"
                        label="Search members by name or #"
                        outlined
                        hide-details
                    ></v-text-field>
                </v-col>
                <v-col cols="12" :md="2" ml-3 mr-3>
                    <v-checkbox
                        height=7
                        v-model="showMembersWithOrdersOnly"
                        label="Hide members with no orders"
                    ></v-checkbox>
                </v-col>
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filteredMembers"
                    
                    item-key="uid"
                    :search="search"
                    show-expand
                    :expanded.sync="expanded"
                >
                    <template v-slot:expanded-item="{ item }">
                        <td :colspan="headers.length">
                        <MemberOrderTable 
                        :items=item.openOrders 
                        :member=item.fullName
                        item-key="id"
                        />
                        </td>
                    </template>
                    <v-alert slot="no-results" :value="true" color="error" icon="warning">
                        Your search for "{{ search }}" found no results.
                    </v-alert>
                </v-data-table>

                <VueJsonToCsv
                    :json-data="filteredOrderExport"
                    :csv-title="orderExportName"
                >
                    <v-btn color="primary" outlined class="mr-6">
                        Export Orders <i class="mdi mdi-export" aria-hidden="true"></i>
                    </v-btn>
                </VueJsonToCsv>
            <!---
                <VueJsonToCsv
                    :json-data="filteredMemberExport"
                    :csv-title="memberExportName"
                >
                    <v-btn color="primary" outlined class="mr-6">
                        Export Member Summaries <i class="mdi mdi-export" aria-hidden="true"></i>
                    </v-btn>
                </VueJsonToCsv>
            -->
            </v-col>


            </template>
        </v-row>
    </v-container>
</template>

<script>
import { mapMutations, mapGetters} from 'vuex'
import api from '@/store/api'
import moment from 'moment-timezone'
import MemberOrderTable from './MemberOrderTable'
import vuex from 'vuex'
import VueJsonToCsv from 'vue-json-to-csv'
export default {
    components: {
        MemberOrderTable,
        VueJsonToCsv
    },
    data () {
        return {
            search: '',
            headers: [
                { width: '1%', text: '#', align: 'left', sortable: true, value: 'memberId' },
                { text: 'First', align: 'left', sortable: true, value: 'firstName' , class: "hidden-xs-only"},
                { text: 'Last', align: 'left', sortable: true, value: 'lastName', class: "hidden-xs-only" },
                { text: 'Orders', align: 'right', sortable: true, value: 'openOrders.length'},
                { text: 'Total', align: 'right', sortable: true, value: 'totalSpend'},
                { text: '', value: 'data-table-expand' }
            ],
            status: "Ordered",
            statusOptions: ["Show All", "Ordered", "Completed", "Refunded"],
            statusIcons: ["schedule", "check", "close"],
            showMembersWithOrdersOnly: true,
            selectedProduct: "Show All",
            loading: false,
            expanded: [],
        }
    },
    mounted () {
        this.refresh()
    },
    computed: {
        ...mapGetters ({
            members: 'adminMemberOrders',
            adminProducts: 'adminProducts'
        }),
        filteredMembers () {
            let filteredMembers = []
            let self = this
            this.members.forEach( function (member) {
                let filteredOrders = self.filterByStatusAndProduct(member.openOrders)
                if(filteredOrders.length > 0 || !self.showMembersWithOrdersOnly){
                    let filteredMember = self.iterationCopy(member)
                    filteredMember.openOrders = filteredOrders
                    filteredMember.totalSpend = self.getTotalSpend(filteredOrders)
                    filteredMembers.push(filteredMember);
                }
            }
            )
            return filteredMembers
        },
        filteredOrderExport () {
            let filteredOrders = []
            this.filteredMembers.forEach( member => {
                member.openOrders.forEach( order => {
                    let record = {
                        "dateOrdered": order.dateOrdered,
                        "lastName": member.lastName,
                        "firstName": member.firstName,
                        "email": member.email,
                        "productId": order.productId,
                        "variation": order.line_items[0].variation_name,
                        "qty": order.line_items[0].quantity,
                        "total": (order.payment.amount/100.0).toLocaleString("en-US", {style:"currency", currency:"USD"})
                    }
                    if(order.fulfillment){
                        record.location = order.fulfillment.location
                    }
                    if(order.options){
                        order.options.forEach( option => {
                            record[option.name] = option.value
                        })
                    }
                    filteredOrders.push(record)
                })
            })
            return filteredOrders
        },
        orderExportName () {
            return "botls_order_export_" + moment.now()
        },
        memberExportName () {
            return "botls_member-orders_export_" + moment.now()
        },
        products () {
            let products = this.adminProducts.sort()
            products.unshift("Show All")
            return products
        },
    },
    methods: {
        iterationCopy(src) {
            let target = {};
            for (let prop in src) {
                if (src.hasOwnProperty(prop)) {
                target[prop] = src[prop];
                }
            }
            return target;
        },
        refresh () {
            this.loading = true
            api.getAdminMemberOrders(this.status)
            .then( () => {
                this.loading = false
            })
            .catch ( error => {
                console.log(error)
            })
        },
        calendar (dateStr) {
            return moment(dateStr, "YYYY-MM-DD @HH:mm").calendar()
        },
        filterByStatusAndProduct ( memberOrders ){
            let status = this.status
            let product = this.selectedProduct
            let filtered = []
            memberOrders.forEach( function (order) {
                if((status == "Show All" || status == order.status) && (product == "Show All" || product == order.name)){
                    filtered.push(order)
                }
            })
            return filtered
        },
        getTotalSpend(filteredOrders){
            let total = 0
            if(filteredOrders != undefined && filteredOrders.length > 0){
                filteredOrders.forEach( order => {
                    total += order.payment.amount
                })
                total = (total/100.0).toFixed(2)
            }
            return total
        }
    }
}
</script>